import { EN, ID } from '../shared/constants/locale';

// Booking List
export const PASSENGER_NAME = {
  [EN]: 'Passenger Name',
  [ID]: 'Nama Penumpang',
};

export const PASSENGER_FIRST_NAME = {
  [EN]: 'Passenger First Name',
  [ID]: 'Nama Depan Penumpang',
};

export const ENTER_PASSENGER_FIRST_NAME = {
  [EN]: 'Enter Passenger First Name',
  [ID]: 'Masukkan Nama Depan Penumpang',
};

export const PASSENGER_LAST_NAME = {
  [EN]: 'Passenger Last Name',
  [ID]: 'Nama Belakang Penumpang',
};

export const ENTER_PASSENGER_LAST_NAME = {
  [EN]: 'Enter Passenger Last Name',
  [ID]: 'Masukkan Nama Belakang Penumpang',
};

export const PASSENGER_MOBILE_NUMBER = {
  [EN]: 'Passenger Mobile Number',
  [ID]: 'No. Handphone Penumpang',
};

export const ENTER_BOOKING_ID = {
  [EN]: 'Enter Booking ID',
  [ID]: 'Masukkan No Pemesanan',
};

export const ENTER_PARTNER_BOOKING_ID = {
  [EN]: 'Enter Partner Booking ID',
  [ID]: 'Masukkan No Pemesanan Mitra',
};

export const BOOKING_ID = {
  [EN]: 'Booking ID',
  [ID]: 'No. Pesanan',
};

export const PARTNER_BOOKING_ID = {
  [EN]: 'Partner Booking ID',
  [ID]: 'No. Pemesanan Mitra',
};

export const RENTAL_START_DATE = {
  [EN]: 'Rental Start Date',
  [ID]: 'Tanggal Mulai Rental',
};

export const RENTAL_END_DATE = {
  [EN]: 'Rental End Date',
  [ID]: 'Tanggal Berakhir Rental',
};

export const RENTAL_START_TIME = {
  [EN]: 'Rental Start Time',
  [ID]: 'Jam Mulai Rental',
};

export const RENTAL_END_TIME = {
  [EN]: 'Rental End Time',
  [ID]: 'Jam Berakhir Rental',
};

export const RENTAL_START_DATE_TIME = {
  [EN]: 'Rental Start Date Time',
  [ID]: 'Tanggal dan Waktu Mulai Rental',
};

export const RENTAL_END_DATE_TIME = {
  [EN]: 'Rental End Date Time',
  [ID]: 'Tanggal dan Waktu Berakhir Rental',
};

// Booking Detail
export const RESEND = {
  [EN]: 'Resend',
  [ID]: 'Kirim Ulang',
};

export const RESEND_BOOKING_INFO = {
  [EN]: 'Resend Booking Info',
  [ID]: 'Kirim Ulang Info Pesanan',
};

export const VIEW_DRIVER_DETAIL = {
  [EN]: 'View Driver Detail',
  [ID]: 'Lihat Detail Sopir',
};

export const DOWNLOAD_BOOKING_DETAIL = {
  [EN]: 'Download Booking Detail',
  [ID]: 'Unduh Detail Pesanan',
};

export const CONFIRM_BOOKING = {
  [EN]: 'Confirm Booking',
  [ID]: 'Konfirmasi Pesanan',
};

export const REJECT_BOOKING = {
  [EN]: 'Reject Booking',
  [ID]: 'Tolak Pesanan',
};

export const BOOKING_REJECTION_MODAL_TITLE = {
  [EN]: 'Are you sure you want to reject the booking?',
  [ID]: 'Apakah Anda yakin ingin menolak pemesanan?',
};

export const REJECTION_DISCLAIMER = {
  [EN]: `<p>By clicking “Reject Booking” you will notify the customer that their booking has been canceled and can be refunded. Inform the user that they are required to select a rejection reason.</p>
  <p>You can see your rejected booking by selecting <b>Booking Status > Rejected</b>.</p>`,
  [ID]: `<p>Dengan mengklik "Tolak Pemesanan," Anda akan memberi tahu pelanggan bahwa pemesanan mereka telah dibatalkan dan dapat dikembalikan. Kasih tahu kalau pengguna wajib untuk memilih alasan penolakan.</p>
  <p>Anda dapat melihat pemesanan yang ditolak dengan memilih <b>Status Pemesanan > Ditolak</b>.</p>`,
};

export const REJECTION_OTHER_REASON_LABEL = {
  [EN]: 'Others (please specify)',
  [ID]: 'Lainnya (mohon spesifikkan)',
};

export const REJECTION_REASON_ERROR_MESSAGE = {
  [EN]: 'Rejection Reason is required',
  [ID]: 'Alasan penolakan wajib diisi'
};

export const REJECTION_REASON_OTHER_ERROR_MESSAGE = {
  [EN]: 'Other Reason is required',
  [ID]: 'Alasan lain wajib diisi'
};

export const BOOKING_REJECTION_REASON = {
  [EN]: 'Rejection Reason',
  [ID]: 'Alasan Penolakan',
};

export const ASSIGN_DRIVER = {
  [EN]: 'Fill Driver Details',
  [ID]: 'Isi Detail Sopir',
};

export const RESEND_ISSUANCE_EMAIL = {
  [EN]: 'Resend Issuance Email',
  [ID]: 'Kirim Ulang Email Pesanan',
};

export const RESEND_ISSUANCE_SMS = {
  [EN]: 'Resend Issuance SMS',
  [ID]: 'Kirim Ulang SMS Pesanan',
};

export const RESEND_CANCELLATION_EMAIL = {
  [EN]: 'Resend Cancellation Email',
  [ID]: 'Kirim Ulang Email Pembatalan Pesanan',
};

export const RESEND_CANCELLATION_SMS = {
  [EN]: 'Resend Cancellation SMS',
  [ID]: 'Kirim Ulang SMS Pembatalan Pesanan',
};

export const BOOKING_NOT_FOUND = {
  [EN]: 'Booking not found. Please modify your search.',
  [ID]: 'Pesanan tidak ditemukan. Mohon ganti pencarian Anda.',
};

export const BOOKING_MANIFEST_RENTAL_DETAIL_UNAUTHORIZED_MESSAGE = {
  [EN]: 'To assign driver, booking must be issued and acknowledged',
  [ID]: 'Untuk memilih driver, booking harus sudah issued dan di-acknowledge',
};

export const DRIVER_ASSIGN_STATUS = {
  [EN]: "Driver's Status [Assigned/Not Assigned]",
  [ID]: 'Status Sopir [Sudah Ditugaskan/Belum Ditugaskan]',
};

export const URGENT_BOOKING = {
  [EN]: 'Urgent Booking',
  [ID]: 'Pesanan Mendesak',
};

export const PENDING_BOOKING = {
  [EN]: 'Pending Booking',
  [ID]: 'Pesanan Tertunda',
};

export const NEED_IMMEDIATE_ACTION = {
  [EN]: 'Need immediate action',
  [ID]: 'Butuh tindakan segera',
};

export const NEED_URGENT_CONFIRMATION = {
  [EN]: 'Need urgent confirmation and assign drivers',
  [ID]: 'Butuh konfirmasi segera dan penugasan sopir',
};

export const NEED_ATTENTION = {
  [EN]: 'Need attention',
  [ID]: 'Butuh perhatian',
};

// Booking Rental Detail
export const RENTAL_DETAILS = {
  [EN]: 'Rental Details',
  [ID]: 'Detail Rental',
};

export const DRIVER_NAME = {
  [EN]: "Driver's Name",
  [ID]: 'Nama Sopir',
};

export const DRIVER_PHONE = {
  [EN]: 'Mobile Number',
  [ID]: 'Nomor Handphone',
};

export const DRIVER_LICENSE_PLATE = {
  [EN]: 'Car Plate Number',
  [ID]: 'Nomor Plat Mobil',
};

export const USE_EXISTING_DRIVER_DETAILS = {
  [EN]: 'Use Existing Details',
  [ID]: 'Gunakan Detail Sebelumnya',
};

export const EDIT_DRIVER_DETAILS = {
  [EN]: 'Edit Driver Details',
  [ID]: 'Ubah Detail Sopir',
};

export const BACK_TO_BOOKING_DETAILS = {
  [EN]: 'Back to Booking Details',
  [ID]: 'Kembali ke Detail Pesanan',
};

export const SELECT_EXISTING_DRIVE = {
  [EN]: 'Select Existing Driver',
  [ID]: 'Pilih Sopir dari Daftar',
};

export const DRIVER_NOT_ON_LIST = {
  [EN]: 'Driver not on list',
  [ID]: 'Sopir belum ada di daftar',
};

export const USE_EXISTING = {
  [EN]: 'Use Existing Driver',
  [ID]: 'Gunakan Sopir dari Daftar',
};

export const NEW_DRIVER = {
  [EN]: 'New Driver',
  [ID]: 'Sopir Baru',
};

export const VEHICLE_PLATE_NUMBER_NOT_ON_LIST = {
  [EN]: 'Vehicle plate number not on list',
  [ID]: 'Plat kendaraan belum ada di daftar',
};

export const DRIVER_NAME_REQUIRED = {
  [EN]: 'Driver name is required',
  [ID]: 'Nama sopir harus diisi',
};

export const DRIVER_PHONE_REQUIRED = {
  [EN]: 'Mobile number is required',
  [ID]: 'Nomor handphone harus diisi',
};

export const TEMPORARY_DRIVER = {
  [EN]: 'Temporary Driver',
  [ID]: 'Sopir Sementara',
};

export const RESULT_NOT_FOUND_TYPE_SOME_WORD_MESSAGE = {
  [EN]: 'Result not found. Type driver name here',
  [ID]: 'Hasil pencarian tidak ditemukan. Ketik nama driver disini',
};

// Booking acknowledge
export const SUCCESS_STATUS = {
  [EN]: 'Booking Confirmation Successful',
  [ID]: 'Konfirmasi Pesanan Berhasil',
};

export const FAILED_STATUS = {
  [EN]: 'You Have Previously Confirmed This Booking',
  [ID]: 'Anda Telah Mengkonfirmasi Pesanan Ini Sebelumnya',
};

export const REDIRECT_TO_BOOKING_PAGE = {
  [EN]: 'You will be redirected to booking page in 5 seconds',
  [ID]: 'Anda akan dialihkan ke halaman detail pesanan dalam 5 detik.',
};

export const CLICK_HERE_TO_REDIRECT = {
  [EN]: 'Click here if it takes longer',
  [ID]: 'Klik di sini jika memakan waktu lebih lama',
};

export const PICKUP_DROPOFF_POINT = {
  [EN]: 'Pickup & Dropoff Point',
  [ID]: 'Lokasi Jemput & Antar',
};

export const NUMBER_OF_CARS = {
  [EN]: 'Number of Cars',
  [ID]: 'Jumlah Mobil',
};

export const NEED_CONFIRMATION = {
  [EN]: 'Need Confirmation',
  [ID]: 'Butuh Konfirmasi',
};

export const CONFIRMED = {
  [EN]: 'Confirmed',
  [ID]: 'Sudah Dikonfirmasi',
};

export const DRIVER_ASSIGNED = {
  [EN]: 'Driver Assigned',
  [ID]: 'Sopir Sudah Ditugaskan',
};

export const DOWNLOAD_BOOKING_LIST = {
  [EN]: 'Download Booking List',
  [ID]: 'Unduh Daftar Pesanan',
};

// Reporting
export const BOOKING_SALES_REPORT = {
  [EN]: 'Sales Report',
  [ID]: 'Laporan Penjualan',
};

export const DOWNLOAD_BOOKING_SALES_REPORT = {
  [EN]: 'Download Sales Report',
  [ID]: 'Unduh Laporan Penjualan',
};

export const DOWNLOAD_BOOKING_REPORT = {
  [EN]: 'Download Booking Report',
  [ID]: 'Unduh Laporan Pemesanan',
};

export const BOOKING_TIME = {
  [EN]: 'Booking Time',
  [ID]: 'Waktu Pesan',
};

export const ISSUANCE_DATE = {
  [EN]: 'Issuance Date',
  [ID]: 'Tanggal Konfirmasi',
};

export const DISTANCE = {
  [EN]: 'Distance',
  [ID]: 'Jarak',
};

export const PRICE_PER_DISTANCE_UNIT = {
  [EN]: 'Price / km',
  [ID]: 'Harga / km',
};

export const BASIC_PRICE = {
  [EN]: 'Basic Price',
  [ID]: 'Harga Dasar',
};

export const TOTAL_PROMO = {
  [EN]: 'Total Promo',
  [ID]: 'Jumlah Promo',
};

export const SURCHARGE = {
  [EN]: 'Surcharge',
  [ID]: 'Harga Tambahan (Surchage)',
};

export const BASIC_SURCHARGE = {
  [EN]: 'Basic Surcharge',
  [ID]: 'Harga Tambahan Dasar (Basic Surchage)',
};

export const SEARCH_TIME_SURCHARGE = {
  [EN]: 'Search Time Surcharge',
  [ID]: 'Harga Tambahan Waktu Pencarian (Surchage Time Surcharge)',
};

export const TOLL = {
  [EN]: 'Toll Fee',
  [ID]: 'Biaya Tol',
};

export const PARKING = {
  [EN]: 'Parking Fee',
  [ID]: 'Biaya Parkir',
};

export const DISCOUNT = {
  [EN]: 'Discount',
  [ID]: 'Diskon',
};

export const RENTAL_PRICE_PER_CAR = {
  [EN]: 'Rental Price (per car)',
  [ID]: 'Harga Sewa (per mobil)',
};

export const TOTAL_PRICE = {
  [EN]: 'Total Price',
  [ID]: 'Harga Total',
};

export const IS_MINIMUM_PRICE = {
  [EN]: 'Minimum Fare',
  [ID]: 'Tarif Minimal',
};

export const PUBLISHED_PRICE = {
  [EN]: 'Published Price (per car)',
  [ID]: 'Harga Resmi (per mobil)',
};

export const BOOKING_TYPE = {
  [EN]: 'Booking Type',
  [ID]: 'Tipe Pesanan',
};

export const ISSUANCE_STATUS = {
  [EN]: 'Issuance Status',
  [ID]: 'Status Konfirmasi',
};

export const DRIVER = {
  [EN]: 'Driver',
  [ID]: 'Pengemudi',
};

export const DRIVER_CANT_PICKUP = {
  [EN]: `Please assign new driver. Driver can't pickup`,
  [ID]: 'Mohon untuk pilih sopir baru. Sopir tidak bisa menjemput',
};

export const LOCAL_TIME = {
  [EN]: 'Local Time',
  [ID]: 'Waktu Lokal',
};

export const SERVICE_CITY = {
  [EN]: 'City/Region',
  [ID]: 'Kota/Wilayah',
};

export const NOTE = {
  [EN]: 'Note',
  [ID]: 'Catatan',
};

export const NOTES = {
  [EN]: 'Notes',
  [ID]: 'Catatan',
};

export const NO_NOTE = {
  [EN]: 'There is no Note',
  [ID]: 'Tidak ada Catatan',
};

export const BOOKING_STATUS = {
  [EN]: 'Booking Status',
  [ID]: 'Status Pesanan',
};

export const ACTION = {
  [EN]: 'Take Action',
  [ID]: 'Lakukan Tindakan',
};

export const PICKUP_DATE_AND_TIME = {
  [EN]: 'Pick-up Date & Time',
  [ID]: 'Tanggal & Waktu jemput',
};

export const NEW_PICKUP_DATE_AND_TIME = {
  [EN]: 'New Pickup Date & Time',
  [ID]: 'Tanggal & Waktu penjemputan Baru',
};

export const VIEW_DETAILS = {
  [EN]: 'Booking Details',
  [ID]: 'Detail Pesanan',
};

export const CONFIRM_BOOKING_BUTTON = {
  [EN]: 'Confirm Booking',
  [ID]: 'Konfirmasi Booking',
};

export const ASSIGN_DRIVER_BUTTON = {
  [EN]: 'Assign Driver',
  [ID]: 'Tugaskan Sopir',
};

export const VIEW_LOCATION_BUTTON = {
  [EN]: "See Driver's Location",
  [ID]: 'Lihat Lokasi Driver',
};

export const TAKE_ACTION_BUTTON = {
  [EN]: 'Take Action',
  [ID]: 'Lakukan Tindakan',
};

export const VIEW_DETAILS_BUTTON = {
  [EN]: 'View Details',
  [ID]: 'Lihat Detil',
};

export const SEE_ALL_NOTES = {
  [EN]: 'See All Notes',
  [ID]: 'Lihat Semua Catatan',
};

export const WAITING_FOR_ACKNOWLEDGE = {
  [EN]: 'Need Confirmation',
  [ID]: 'Perlu Dikonfirmasi',
};

export const ACKNOWLEDGED = {
  [EN]: 'Need to Assign Driver',
  [ID]: 'Perlu Tugaskan Sopir',
};

export const DRIVER_ASSIGNED_STATUS = {
  [EN]: 'Driver Assigned',
  [ID]: 'Sudah Tugaskan Sopir',
};

export const DRIVER_CONFIRMED = {
  [EN]: 'Driver Confirmed',
  [ID]: 'Sopir Sudah Konfirmasi',
};

export const TRIP_COMPLETED = {
  [EN]: 'Trip Completed',
  [ID]: 'Perjalanan Selesai',
};

export const REFUNDED = {
  [EN]: 'Refunded',
  [ID]: 'Sudah Refund',
};

export const REQUEST_REFUND = {
  [EN]: 'Need to Process Refund',
  [ID]: 'Perlu Proses Refund',
};

export const RESCHEDULED = {
  [EN]: 'Rescheduled',
  [ID]: 'Sudah Reschedule',
};

export const REQUEST_RESCHEDULE = {
  [EN]: 'Need to Process Reschedule',
  [ID]: 'Perlu Proses Reschedule',
};

export const POTENTIAL_LATE = {
  [EN]: 'Driver May Be Late',
  [ID]: 'Sopir Mungkin Terlambat',
};

export const PAX_AGREE_TO_WAIT = {
  [EN]: 'Passenger Agrees to Wait',
  [ID]: 'Penumpang Mau Menunggu',
};

export const REQUEST_ACKNOWLEDGE = {
  [EN]: 'Need Confirmation Immediately',
  [ID]: 'Perlu Segera Dikonfirmasi',
};

export const REQUEST_ASSIGN_DRIVER = {
  [EN]: 'Need to Assign Driver Immediately',
  [ID]: 'Perlu Segera Tugaskan Sopir',
};

export const CAR_PLATE_NUMBER = {
  [EN]: 'Car Plate Number',
  [ID]: 'Nomor Plat Mobil',
};

export const DRIVER_STATUS = {
  [EN]: 'Driver Status',
  [ID]: 'Status Sopir',
};

export const SORT_BY = {
  [EN]: 'Sort By',
  [ID]: 'Urutkan',
};

export const NO_BOOKING = {
  [EN]: 'Booking Not Found',
  [ID]: 'Pesanan Tidak Ditemukan',
};

export const SUPPLIER_ACTION = {
  [EN]: 'Supplier Action',
  [ID]: 'Tindakan Supplier',
};

export const SUPPLIER_BOOKING_ID = {
  [EN]: 'Supplier Booking Id',
  [ID]: 'No Pesanan Supplier',
};

export const TRAVELOKA_BOOKING_ID = {
  [EN]: 'Traveloka Booking Id',
  [ID]: 'No Pesanan Traveloka',
};

export const PICKUP_DROPOFF_LOCATION = {
  [EN]: 'Pick-up and Drop-off Location',
  [ID]: 'Lokasi Jemput dan Antar',
};

export const BASIC_PRICE_SURCHARGE = {
  [EN]: 'Basic Rental + Surcharge',
  [ID]: 'Basic Rental + Surcharge',
};

export const BASIC_PRICE_AFTER_COMMISSION = {
  [EN]: 'Rental Net Price + Surcharge',
  [ID]: 'Harga Bersih Rental + Surcharge',
};

export const TOTAL_ADDON__AFTER_COMMISSION = {
  [EN]: 'Add-on Net Price',
  [ID]: 'Harga Bersih Add-on',
};

export const NET_TO_AGENT = {
  [EN]: 'Net Amount to Supplier',
  [ID]: 'Jumlah Bersih untuk Supplier',
};

export const DOWNLOADING = {
  [EN]: 'Downloading',
  [ID]: 'Mengunduh',
};

export const DOWNLOAD_FAILED = {
  [EN]: 'Failed to download, please contact system administrator',
  [ID]: 'Gagal mengunduh file, mohon kontak sistem admin',
};

export const CUTOFF_TYPE_END_OF_DAY = {
  [EN]: 'End of Day',
  [ID]: 'End of Day',
};

export const CUTOFF_TYPE_TWENTY_FOUR_HOURS = {
  [EN]: '24 Hours',
  [ID]: '24 Hours',
};
